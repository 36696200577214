const loadImage = (src) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
}

const visionAPI = async (imageSrc) => {
    // 删除关于 Google Cloud Vision 的代码

    const url = "https://corpusmaker.eastlinker.com/cloudVision";

    // 准备图片数据
    const image = await loadImage(imageSrc);

    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);
    const base64string = canvas.toDataURL('image/png').replace(/^data:image\/png;base64,/, '');

    const body = { image: base64string };
    console.log(JSON.stringify(body))

    try {
        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body)
        });

        if(response.ok) {
            let data = await response.json();
            // 返回整个OCR的结果，包括位置信息
            return data.data


        } else {
            console.error(`Request failed with status: ${response.status}`);
            console.error(`Response: ${await response.text()}`);
            throw new Error(`Failed with ${response.status}: ${response.statusText}`);
        }

    } catch(error) {
        console.error("Request failed:");
        console.error(error);
        throw "Internet connection error";
    }

}

export default visionAPI;