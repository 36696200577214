import React from "react";
import {Button, OverlayTrigger, Row} from "react-bootstrap";
import Upload from "../Upload";
import {renderTooltip} from "../TypeSelect";
import {Book, TextWrap} from "react-bootstrap-icons";
import {Tooltip} from "react-bootstrap";
import {Col} from "react-bootstrap";
import {BsFillFileTextFill} from "react-icons/bs";
import StepAdvice from "../StepAdvice";

const ToolBar = (props) => {
    const {setPdfFile,showPdfViewerOrNot,setShowPdfViewerOrNot,setShowResultOrNot,showResultOrNot
    ,pdfViewerFirstClick,setPdfViewerFirstClick,blocks} = props;
    const [pdfFileName,setPdfFileName] = React.useState("")
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.message}
        </Tooltip>
    );
    return(
        <>
            <Row style={{height:"5vh"}}>
                {/*居左显示*/}
                <Col>
                <Upload
                    setPdfFile={setPdfFile}
                    setPdfFileName={setPdfFileName}
                    pdfFileName={pdfFileName}
                />
                </Col>
                {/*居右显示*/}
                <Col className={"justify-content-center"}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ message: 'PDFビューアー' })}
                >
                    <Button variant={showPdfViewerOrNot?"primary":"outline-primary"} size="sm" className={"me-1 mb-1"} onClick={()=>{
                        setShowPdfViewerOrNot(!showPdfViewerOrNot)
                        setShowResultOrNot(false)
                        setPdfViewerFirstClick(true)
                    }
                    }>
                        <Book />
                    </Button>
                </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip({ message: '出力ビューアー' })}
                    >
                        <Button variant={showResultOrNot?"primary":"outline-primary"} size="sm" className={"me-1 mb-1"} onClick={()=>{
                            setShowResultOrNot(!showResultOrNot)
                            setShowPdfViewerOrNot(false)
                        }}>
                            <BsFillFileTextFill/>
                        </Button>
                    </OverlayTrigger>
                </Col>
                {/*居右显示*/}
                <Col md={6} className={"justify-content-right"} style={{overflowX:"scroll"}}>
                    <StepAdvice
                        pdfViewerFirstClick={pdfViewerFirstClick}
                        pdfFileName={pdfFileName}
                        blocks={blocks}
                        showPdfViewerOrNot={showPdfViewerOrNot}
                    />
                </Col>

            </Row>
        </>
    )
}
export default ToolBar;