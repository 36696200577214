// src/components/Upload/ocr.js
// 上传文件
import React, { useRef } from 'react';  // 引入useRef
import {Book} from "react-bootstrap-icons";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import {FaFileUpload} from "react-icons/fa";

const Upload = (props) => {
    const {setPdfFile,pdfFileName,setPdfFileName} = props;
    const onFileSelected = setPdfFile

    const fileInputRef = useRef(null);  // 创建一个ref来引用input元素
    const [fileName,setFileName] = React.useState("");

    const handleFileInput = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setFileName(file.name);
            setPdfFileName(file.name);
            onFileSelected(file);
        } else {
            alert('Please upload a PDF file.');
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();  // 在按钮点击时触发input的点击事件
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.message}
        </Tooltip>
    );

    return (
        <>
            <div>
            <label className="custom-file-upload">
                <input
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileInput}
                    style={{display: 'none'}}
                    ref={fileInputRef}  // 将ref属性添加到input元素
                />
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ message: 'PDFファイルを選択してください' })}
                >
                    <Button
                        variant="primary"
                        size="sm"
                        className={"me-1 mb-1"}
                        onClick={handleButtonClick}  // 将按钮的onClick属性设置为handleButtonClick函数
                    >
                        <FaFileUpload />
                    </Button>
                </OverlayTrigger>
            </label>
            {/*显示pdf文件名*/}
            {fileName === "" ? <span>ファイルを選択してください</span> : fileName}
            </div>
        </>
    );
};

export default Upload;
