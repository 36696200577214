import React, {useEffect} from 'react';
import "./index.css"

const StepAdvice = (props) => {
    const {pdfViewerFirstClick,pdfFileName,blocks,showPdfViewerOrNot} = props;
    const [stepList,setStepList] = React.useState({
        "step1":"PDFファイルをアップロードしてください",
        "step2":"PDFビューアーでPDFを表示してください",
        "step3":"PDFビューアーでテキストを選択してください",
        "step4":"全てのテキストを選択した後PDFビューアーを閉じて出力を確認してください",
        "step5":"出力の修正を行ってください",
        "step6":"全ての修正が終了した後，出力ビューアーで出力を確認してください"
    })
    const [step,setStep] = React.useState(1)
    useEffect(() => {
        if(step === 1 && pdfFileName !== ""){
            setStep(2)
        }
    }, [pdfFileName]);
    useEffect(() => {
        if(step ===2 && pdfViewerFirstClick){
            setStep(3)
        }
    }, [pdfViewerFirstClick]);
    useEffect(() => {
        if (step === 3 && blocks.length !== 0){
            setStep(4)
    }
    }, [blocks]);
    useEffect(() => {
        if (step === 4 && showPdfViewerOrNot===false){
            setStep(5)
        }
    }, [showPdfViewerOrNot]);
    useEffect(() => {
        if (step === 5){
            setStep(6)
        }
    }, [blocks]);
    useEffect(() => {
        console.log(step)
    }, [step]);

    return (
        <>
            <div className={"step-advice"}>
                <div className={"step-advice-content"}><span>Tips: {stepList["step"+step]}</span></div>
            </div>



        </>
    )
}
export default StepAdvice;