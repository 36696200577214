import React from "react";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Book, People, TextParagraph} from 'react-bootstrap-icons';
import {AiFillDelete, AiOutlineFieldNumber} from "react-icons/ai";
import {GoCrossReference} from "react-icons/go";
import {VscDebugContinue} from "react-icons/vsc";

const TypeSelect = (props) => {
    const {blocks, index, setBlocks} = props;
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.message}
        </Tooltip>
    );
    const deleteBlock = () => {
        //删除当前block这个state数组中里的当前元素
        const newBlocks = [...blocks]
        newBlocks.splice(index, 1)
        setBlocks(newBlocks)
        console.log("delete block")

    }
    const setBlockType = (blockType) => {
        const newBlocks = [...blocks]
        console.log("id", index)
        console.log(newBlocks[index])
        newBlocks[index].type = blockType
        setBlocks(newBlocks)
    }
    return (
        <>
            <OverlayTrigger
                placement="top"
                delay={{show: 250, hide: 200}}
                overlay={renderTooltip({message: 'Journal'})}
            >
                <Button variant={blocks[index].type === "journal" ? "primary" : "outline-primary"} size="sm"
                        className={"me-1 mb-1"} onClick={() => setBlockType("journal")}>
                    <Book/>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{show: 250, hide: 200}}
                overlay={renderTooltip({message: 'Author'})}
            >
                <Button variant={blocks[index].type === "author" ? "primary" : "outline-primary"} size="sm"
                        className={"me-1 mb-1"} onClick={() => setBlockType("author")}>
                    <People/>
                </Button>
            </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{show: 250, hide: 200}}
                    overlay={renderTooltip({message: 'Page Number'})}
                >
                    <Button variant={blocks[index].type === "pageNum" ? "primary" : "outline-primary"} size="sm"
                            className={"me-1 mb-1"} onClick={() => setBlockType("pageNum")}>
                        <AiOutlineFieldNumber/>
                    </Button>
                </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        delay={{show: 250, hide: 200}}
                        overlay={renderTooltip({message: 'H1 Level Title'})}
                    >
                        <Button variant={blocks[index].type === "H1" ? "primary" : "outline-primary"} size="sm"
                                className={"me-1 mb-1"} onClick={() => setBlockType("H1")}>
                            H1
                        </Button>
                    </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 250, hide: 200}}
                            overlay={renderTooltip({message: 'H2 Level Title'})}
                        >
                            <Button variant={blocks[index].type === "H2" ? "primary" : "outline-primary"} size="sm"
                                    className={"me-1 mb-1"} onClick={() => setBlockType("H2")}>
                                H2
                            </Button>
                        </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                delay={{show: 250, hide: 200}}
                                overlay={renderTooltip({message: 'H3 Level Title'})}
                            >
                                <Button variant={blocks[index].type === "H3" ? "primary" : "outline-primary"} size="sm"
                                        className={"me-1 mb-1"} onClick={() => setBlockType("H3")}>
                                    H3
                                </Button>
                            </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{show: 250, hide: 200}}
                                    overlay={renderTooltip({message: 'H4 Level Title'})}
                                >
                                <Button variant={blocks[index].type === "H4" ? "primary" : "outline-primary"} size="sm"
                                        className={"me-1 mb-1"} onClick={() => setBlockType("H4")}>
                                    H4
                                </Button>
                            </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{show: 250, hide: 200}}
                                    overlay={renderTooltip({message: 'Body'})}
                                >
                                    <Button variant={blocks[index].type === "body" ? "primary" : "outline-primary"}
                                            size="sm" className={"me-1 mb-1"} onClick={() => setBlockType("body")}>
                                        <TextParagraph/>
                                    </Button>
                                </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{show: 250, hide: 200}}
                overlay={renderTooltip({message: 'Body Continue'})}
            >
                <Button variant={blocks[index].type === "bodyContinue" ? "primary" : "outline-primary"}
                        size="sm" className={"me-1 mb-1"} onClick={() => setBlockType("bodyContinue")}>
                    <VscDebugContinue/>
                </Button>
            </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 250, hide: 200}}
                                        overlay={renderTooltip({message: 'References'})}
                                    >
                                        <Button
                                            variant={blocks[index].type === "references" ? "primary" : "outline-primary"}
                                            size="sm" className={"me-1 mb-1"} onClick={() => setBlockType("references")}>
                                            <GoCrossReference/>
                                        </Button>
                                    </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{show: 250, hide: 200}}
                                            overlay={renderTooltip({message: 'Delete'})}
                                        >
                                            <Button variant="danger" size="sm" className={"me-1 mb-1"}
                                                    onClick={() => deleteBlock()}>
                                                <AiFillDelete/>
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                    )
                                    }
                                    export default TypeSelect;