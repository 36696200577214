import React, {useEffect, useRef, useState} from 'react';
import {Container} from "react-bootstrap";
import VisionAPI from "../../utils/ocr";
import organizeocrdata from "../../utils/organizeocrdata";
import {Row} from "react-bootstrap";


const Viewer = (props) => {
    const {
        pdfFile,
        pdfPage, setPdfPage,
        blocks, setBlocks
    }
        = props

    //PDF文件
    const [pdfDocument, setPdfDocument] = useState(null);
    //用来储存PDFCanvas的ref
    const pdfCanvasRef = useRef(null);
    const blockCanvasRef = useRef(null)
    const [tempShowBlock,setTempShowBlock]= useState({})
    const [tempShowBlockShow,setTempShowBlockShow]=useState(false)
    const [shouldScroll, setShouldScroll] = useState(false);
    const pdfScrollRef = useRef(null)
    const [showOCRingWindow,setShowOCRingWindow]=useState(false)
    const [pdfCanvasSizeLog,setPdfCanvasSizeLog]=useState([0,0])

    //用来储存鼠标的location
    const [locations, setLocations] = useState({
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0
    })
    //监测鼠标按下
    const handleMouseDown = (e) => {
        setTempShowBlockShow(true)
        const rect = pdfCanvasRef.current.getBoundingClientRect();
        setLocations({startX: e.clientX - rect.left, startY: e.clientY - rect.top})
    };
    const handleMouseMove = (e) => {
        const rect = pdfCanvasRef.current.getBoundingClientRect();
        let endX = e.clientX - rect.left;
        let endY = e.clientY - rect.top;
        let width = endX - locations.startX;
        let height = endY - locations.startY;
        //获取在整个页面中的鼠标的y坐标
        const pageY = e.pageY;
        ///滚动

        const container = pdfScrollRef.current  // 确保为你的容器指定一个类名或id
        const parentHeight = pdfScrollRef.current.clientHeight;

        // 如果鼠标在容器边缘的10%范围内滚动
        // 检查鼠标是否在容器边缘的10%范围内
        if (tempShowBlockShow && pageY > parentHeight * 0.98) {
            setShouldScroll(true);
        } else {
            setShouldScroll(false);
        }



        //修改tempShowBlock的数值
        //如果startX和startY不为0
        if(locations.startX!==0&&locations.startY!==0){
            setTempShowBlock({
                width: width,
                height: height,
                x: locations.startX,
                y: locations.startY,
                page: pdfPage
            });
        }


    };

    useEffect(() => {
        let timerId;

        if (shouldScroll) {
            // 如果应该滚动页面，创建一个定时器
            timerId = setInterval(() => {
                const container = pdfScrollRef.current;

                // 滚动页面
                container.scrollBy({ top: 20, behavior: 'smooth' });
            }, 100);  // 每100毫秒滚动一次
        } else {
            // 如果不应该滚动页面，清除定时器
            clearInterval(timerId);
        }

        return () => {
            // 在组件卸载时清除定时器
            clearInterval(timerId);
        };
    }, [shouldScroll]);
//生成当前block的完整文本（按顺序合并所有item.text）
    const generateBlockText =(organizedResult)=>{
        let blockText = "";
        organizedResult.map((item, itemIndex) => (
            blockText += item.text
        ))
        //更新对应index的blocks的block，将blockText赋值给blockText属性

        return blockText;

    }
    //监测鼠标抬起
    const handleMouseUp = async (e) => {
        const rect = pdfCanvasRef.current.getBoundingClientRect();
        let endX = e.clientX - rect.left;
        let endY = e.clientY - rect.top;
        let width = endX - locations.startX;
        let height = endY - locations.startY;
        setTempShowBlockShow(false)
        //如果width和height不为0
        if(width!==0&&height!==0) {
            //新增一个block
            console.log("add new block")
            const image = extractBlock(width, height)
            //新增一个带图片的block
            //对image进行Google Cloud Vision的OCR,异步函数
            //显示OCR中的窗口
            setShowOCRingWindow(true)

            const ocrResult = await VisionAPI(image.src)
            //如果ocrResult不是undefined
            let organizedResult=[]
            if (ocrResult !== undefined) {
                organizedResult = organizeocrdata(ocrResult.pages)
            } else{
                organizedResult = []
        }
        let fullText = generateBlockText(organizedResult)
                setBlocks([...blocks, {
                    id: blocks.length,
                    page: pdfPage,
                    x: locations.startX,
                    y: locations.startY,
                    width: width,
                    height: height,
                    image: image,
                    ocrResult: organizedResult,
                    //用来备份原始的ocrResult
                    originalOcrResult:organizedResult,
                    pdfName: pdfFile.name,
                    fullText: fullText,
                    //记录用户是否编辑过block
                    userBlockEdited: false,
                    //用来备份原始的fullText
                    originalFullText:fullText,
                    //用来记录block的类型
                    type: "unclassified",
                    pdfSize:pdfCanvasSizeLog
                }])
            console.log(pdfCanvasSizeLog)

        }
        setShowOCRingWindow(false)
        setLocations({startX: 0, startY: 0, endX: 0, endY: 0})
    }

    // 使用 useEffect 钩子来处理 pdfFile
    useEffect(() => {
        // 检查 pdfFile 是否存在且其类型是否为 'application/pdf'
        if (pdfFile && pdfFile.type === 'application/pdf') {
            // 创建一个新的 <script> 元素
            const pdfScript = document.createElement('script');
            // 设置 script 元素的 src 属性，以加载 pdf.js 库
            pdfScript.src = '/js/pdf.js';
            // 设置 script 元素的 onload 事件处理器，以在 pdf.js 加载完成后执行代码
            pdfScript.onload = () => {
                // 创建一个 loadingTask 对象，以加载 PDF 文件
                const loadingTask = window.pdfjsLib.getDocument({
                    url: URL.createObjectURL(pdfFile),
                    cMapUrl: "/js/cmaps/",
                    cMapPacked: true,
                });

                // 等待 loadingTask 完成，然后获取 PDF 文件的 pdfDocument 对象
                loadingTask.promise.then(pdfDocument => {
                    // 将 pdfDocument 对象保存到组件的状态中
                    setPdfDocument(pdfDocument);
                });
            };
            // 将 script 元素添加到文档的 body 中，以触发 pdf.js 库的加载
            document.body.appendChild(pdfScript);
        }
        // 指定 useEffect 的依赖项数组，以便在 pdfFile 或 blocks 变量更改时重新运行效果
    }, [pdfFile]);

    const pdfRenderPage = () => {
        if (pdfDocument) {
            pdfDocument.getPage(pdfPage).then(page => {
                const pdfCanvas = pdfCanvasRef.current;
                const blockCanvas = blockCanvasRef.current;
                const pdfContext = pdfCanvas.getContext('2d');
                let pdfScale = 1;
                let pdfViewport = page.getViewport({scale: pdfScale});
                //获取父元素的宽度
                const parentWidth = pdfCanvas.parentElement.clientWidth;
                //获取pdfViewport.width
                const pdfWidth = pdfViewport.width;
                //调整scale
                pdfScale = parentWidth / pdfWidth;
                pdfViewport = page.getViewport({scale: pdfScale});
                pdfCanvas.width = pdfViewport.width;
                pdfCanvas.height = pdfViewport.height;
                setPdfCanvasSizeLog([pdfViewport.width,pdfViewport.height])
                // 设置 blockCanvas 的样式
                blockCanvas.width =  pdfViewport.width;
                blockCanvas.height = pdfViewport.height;
                blockCanvas.style.position = 'absolute';
                blockCanvas.style.left = pdfCanvas.offsetLeft + 'px';
                blockCanvas.style.top = pdfCanvas.offsetTop + 'px';
                drawBlocks()
                //设置pdfCanvas具体参数
                const pdfRenderContext = {
                    canvasContext: pdfContext,
                    viewport: pdfViewport,
                };
                //渲染pdf页面
                page.render(pdfRenderContext);

            });
        }
    };
    useEffect(pdfRenderPage, [pdfDocument, pdfPage]);



    //绘制block
    const drawBlocks = () => {
        const canvas = blockCanvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        // 开始新的绘制路径
        context.beginPath();
        //绘制文件名相同且本页的blocks
        blocks.filter(block => block.page === pdfPage & block.pdfName === pdfFile.name).forEach(block => {
            context.rect(block.x, block.y, block.width, block.height);
        }

        )
        // 设置绘制路径的样式
        context.strokeStyle = 'red';
        context.lineWidth = 2;
        // 绘制路径
        context.stroke();
        // 关闭路径
        context.closePath();
        // 开始新的绘制路径
        context.beginPath();
        //绘制tempShowBlock
        if (tempShowBlock.page === pdfPage) {
            if(tempShowBlockShow===true) {
                //绘制tempShowBlock
                context.rect(tempShowBlock.x, tempShowBlock.y, tempShowBlock.width, tempShowBlock.height);
            }
        }

        // 设置绘制路径的样式
        context.strokeStyle = 'blue';
        context.lineWidth = 2;
        // 绘制路径
        context.stroke();
        // 关闭路径
        context.closePath();
    };

    useEffect(() => {
        drawBlocks();
    }, [blocks, tempShowBlock]);

    const extractBlock = (width,height) => {
            const pdfCanvas = pdfCanvasRef.current;
            //提取最后一个block范围的pdf的图片
            const pdfBlock = pdfCanvas.getContext('2d').getImageData(locations.startX,locations.startY, width, height)
            //把ImageData转换为PNG
            const canvas = document.createElement('canvas');
            canvas.width = pdfBlock.width;
            canvas.height = pdfBlock.height;
            const context = canvas.getContext('2d');
            context.putImageData(pdfBlock, 0, 0);
            const pngBlock = canvas.toDataURL();
            //把pngBlock转换为Image
            const image = new Image();
            image.src = pngBlock;
            //把image存储到imageBlock
            return image

    }


    const goToPage = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= pdfDocument.numPages) {
            setPdfPage(pageNumber);
        }
    };

    return(
        <>
            {/*如果showOCRingWindow为true，那么显示OCR中的窗口*/}
            {showOCRingWindow&&(
                <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:3}}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {/*显示当前窗口的高度*/}
            <div className={"m-1"}>
                <button onClick={() => setPdfPage(pdfPage - 1)} disabled={pdfPage === 1}>
                    Previous
                </button>
                <button onClick={() => setPdfPage(pdfPage + 1)}
                        disabled={pdfDocument && pdfPage === pdfDocument.numPages}>
                    Next
                </button>
                <input type="number" min="1" max={pdfDocument ? pdfDocument.numPages : ''}
                       onChange={(e) => goToPage(parseInt(e.target.value))} value={pdfPage}/>
            </div>
            <div style={{position:"relative", height: "95%" ,overflowY: 'scroll'}} className={"p-0"} ref={pdfScrollRef} >
                {/*渲染PDF页面*/}
                <canvas id="pdf" style={{zIndex: 1}} ref={pdfCanvasRef} ></canvas>
                {/*渲染block*/}
                <canvas style={{zIndex: 2}} ref={blockCanvasRef} onMouseMove={handleMouseMove} onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}></canvas>
            </div>


        </>

    )
}
export default Viewer;