// src/App.js
import React, {useRef, useState} from 'react';
import Upload from './components/Upload';
import Viewer from "./components/Viewer";
import BlockList from "./components/BlockList";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap"
import {Button, Col, Row, Tooltip} from "react-bootstrap";
import {Book} from "react-bootstrap-icons";
import {OverlayTrigger} from "react-bootstrap";
import ToolBar from "./components/ToolBar";
import Result from "./components/Result";
import {Modal} from "react-bootstrap";
import {AiFillInfoCircle, AiOutlineInfo, AiOutlineInfoCircle} from "react-icons/ai";
import Export from "./components/Export";

const App = () => {
    //存放pdf文件
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfPage, setPdfPage] = useState(1)
    //存储blocks
    const [blocks, setBlocks] = useState([])
    const blockListScrollRef = useRef(null);
    const [showPdfViewerOrNot, setShowPdfViewerOrNot] = useState(false);
    const [showResultOrNot, setShowResultOrNot] = useState(false);
    const [mainNotice,setMainNotice] = useState(true)
    const [pdfViewerFirstClick,setPdfViewerFirstClick] = useState(false)
    const [result,setResult] = useState("")
    const [uploading,setUploading] = useState(false)
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.message}
        </Tooltip>
    );



    return (
        // 设定高度为当前窗口高度
        <div className={"App"}>
            <Modal show={mainNotice}>
                <Modal.Header closeButton>
                    <Modal.Title>corpusMaker</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>corpusMakerは、論文のPDFファイルをコーパスに変換するためのツールです。</p>
                    <p>このツールを使用するには、まず左上の「ファイルを選択」をクリックして、PDFファイルをアップロードしてください。</p>
                    <p style={{fontWeight:"bold"}}>ChromeもしくはEdgeをお使いください。</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>{setMainNotice(!mainNotice)}}>
                        閉じる
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*如果Uploading是True则在屏幕中间显示Uploading*/}
            {uploading && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    zIndex: '9999'
                }}>
                    <h1>Uploading...</h1>
                </div>
            )

                }
            <ToolBar
                setPdfFile={setPdfFile}
                showPdfViewerOrNot={showPdfViewerOrNot}
                setShowPdfViewerOrNot={setShowPdfViewerOrNot}
                setShowResultOrNot={setShowResultOrNot}
                showResultOrNot={showResultOrNot}
                pdfViewerFirstClick={pdfViewerFirstClick}
                setPdfViewerFirstClick={setPdfViewerFirstClick}
                blocks={blocks}
            />
            {showPdfViewerOrNot}
            <Row style={{display: 'flex'}}>


                <Col md={0} style={{border: '1px solid black',overflowY: 'scroll', height: '95vh'}} ref={blockListScrollRef} className={"p-2"}>
                    <BlockList
                        blocks={blocks}
                        setBlocks={setBlocks}
                        scrollRef={blockListScrollRef}
                        showPdfViewerOrNot={showPdfViewerOrNot}
                    />
                </Col>
                {showPdfViewerOrNot && (
                <Col md={8} className={"p-1"}
                     style={{border: '1px solid black', overflowY: 'scroll', height: '95vh'}}>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{show: 250, hide: 200}}
                        overlay={renderTooltip({message: 'テキスト化したい範囲をドラッグ&ドロップで選択して下さい。'})}
                    >

                    <span style={{fontWeight: 'bold'}}>PDFビューアー<AiFillInfoCircle style={{color:"blue"}}/></span>

                    </OverlayTrigger>


                    <hr/>
                    <Viewer
                        //传递PDF文件
                        pdfFile={pdfFile}
                        //PDF页码
                        pdfPage={pdfPage}
                        setPdfPage={setPdfPage}
                        //Blocks信息
                        blocks={blocks}
                        setBlocks={setBlocks}
                    />
                </Col>

                    )}
                {showResultOrNot && (
                <Col md={3} style={{border: '1px solid black'}}>
                    <span style={{fontWeight: 'bold'}}>出力プレビュー</span>
                    <Export
                        result={result}
                        blocks={blocks}
                        pdfFile={pdfFile}
                        uploading={uploading}
                        setUploading={setUploading}
                    />
                    <hr/>
                    <Result
                        blocks={blocks}
                        setResult={setResult}
                        result={result}
                    />
                </Col>
                    )}

            </Row>
        </div>

    );
};

export default App;
