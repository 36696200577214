import React, {useEffect} from 'react';
import {useState} from "react";
import ReactMarkdown from 'react-markdown';

const Result = (props) => {
    const {blocks,result,setResult} = props;
    const connectBlocksFullText = (blocks) => {
        let fullText = "";

        blocks && blocks.forEach((block, index) => {
            if (block.type === "H1") {
                fullText += "# " + block.fullText + "  \n";
            } else if (block.type === "H2") {
                fullText += "## " + block.fullText + "  \n";
            } else if (block.type === "H3") {
                fullText += "### " + block.fullText + "  \n";
            } else if (block.type === "bodyContinue"){
                //删除最后四个字符
                fullText = fullText.substring(0,fullText.length-4)
                fullText += block.fullText + "  \n";
            }
            else{
                fullText += block.fullText + "  \n";
            }
            setResult(fullText)
        })

    }
    const customStyles = {
        fontFamily: 'Noto Sans CJK, sans-serif', // Use Noto Sans CJK for Japanese and Chinese text
    };
    useEffect(() => {
        connectBlocksFullText(blocks)
    }, [blocks])

    return(
        <>
            <ReactMarkdown style={customStyles}>{result}</ReactMarkdown>
        </>
    )

}
export default Result;