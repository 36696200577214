import React, {useEffect} from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import TypeSelect from "../TypeSelect";
import {AiOutlineInfoCircle} from "react-icons/ai"

const BlockList = (props) => {
    const {blocks, setBlocks, scrollRef, showPdfViewerOrNot} = props;

    const updateBlockText = (block) => {
        let blockText = "";
        block.ocrResult.map((item, itemIndex) => (
            blockText += item.text
        ))
        return blockText;
    }

    const handleTextChange = (index, itemIndex, e) => {
        const newText = e.target.innerText;
        const updatedBlocks = [...blocks];
        updatedBlocks[index].ocrResult[itemIndex].text = newText;
        updatedBlocks[index].fullText = updateBlockText(updatedBlocks[index]);

        setBlocks(updatedBlocks);
    };

    const handleFullTextChange = (index, e) => {
        const updatedText = e.target.innerText;
        const updatedBlocks = [...blocks];
        updatedBlocks[index].fullText = updatedText;
        updatedBlocks[index].userBlockEdited = true;

        setBlocks(updatedBlocks);
    }
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.message}
        </Tooltip>
    );

    useEffect(() => {
        if (showPdfViewerOrNot) {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
        }
    }, [blocks]);

    return (
        <div className={"blockList"}>
            <div style={{backgroundColor: "darkgray"}}>
                <Row className={"p-3"}>
                    <Col md={2} className={"p-0"} style={{color: "white"}}>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{show: 250, hide: 200}}
                            overlay={renderTooltip({message: 'ブロックのタイプを選択してください。'})}
                        >
                            <div>タグ<AiOutlineInfoCircle/></div>

                        </OverlayTrigger>
                    </Col>

                    <Col md={7} className={"p-0"} style={{color: "white"}}>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{show: 250, hide: 200}}
                            overlay={renderTooltip({message: 'PDFの文字とOCRの結果を比較しながら，一文字ごとの修正が可能です。'})}
                        >
                            <div>文字<AiOutlineInfoCircle/></div>

                        </OverlayTrigger>
                    </Col>


                    <Col md={3} className={"p-0"} style={{color: "white"}}>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{show: 250, hide: 200}}
                            overlay={renderTooltip({message: '段落の修正が可能です。段落修正をした後は文字修正ができなくなります。'})}
                        >
                            <div>段落<AiOutlineInfoCircle/></div>

                        </OverlayTrigger>
                    </Col>
                </Row>
            </div>

            {blocks.length !== 0 && [...blocks].map((block, index) => (
                <div className={"block"} key={index} style={{border: "0px solid #000"}}>

                    <Row style={{display: 'flex'}} className={"p-3"}>
                        <Col md={2} className={"p-0"}>
                            <div className={"typeSelect p-0"}>
                                <TypeSelect
                                    blocks={blocks}
                                    index={index}
                                    setBlocks={setBlocks}

                                />
                            </div>
                        </Col>
                        <Col md={7} className={"p-0"}
                             style={{border: '0px solid black', position: "relative", overflowX: "scroll"}}>
                            <div className={"blockCheckArea"} style={{border: '0px solid black'}}>
                                <div className={"blockImage"}>
                                    <img src={block.image.src} alt={`Block ${index}`}/>
                                </div>
                                <div className={"blockText"} style={{position: "absolute", top: 0, left: 0}}>
                                    {block && block.ocrResult.map((item, itemIndex) => (
                                        <span
                                            key={itemIndex}
                                            contentEditable={
                                                //如果userBlockEdited为false，那么就可以编辑
                                                !block.userBlockEdited
                                            }
                                            onBlur={(e) => handleTextChange(index, itemIndex, e)}
                                            style={{
                                                position: 'absolute',
                                                top: `${item.start_y}px`,
                                                left: `${item.start_x}px`,
                                                width: `${(item.end_x - item.start_x) * 2 + 1}px`,
                                                height: `${(item.end_y - item.start_y) * 2 + 1}px`,
                                                background: 'rgba(255, 255, 255, 0)',
                                                fontSize: `${item.end_y - item.start_y}px`,
                                                lineHeight: `${item.end_y - item.start_y}px`,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                // 如果userBlockEdited为false则红色，否则为黑色
                                                color: !block.userBlockEdited ? 'red' : 'orange',
                                                // 如果userBlockEdited为true则透明度为90%
                                                opacity: block.userBlockEdited ? '0.8' : '1',
                                            }}
                                            dangerouslySetInnerHTML={{__html: item.text}}
                                        />
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col md={3} style={{overflowX: "scroll"}}>
                            <span
                                style={{overflowX: 'scroll'}}
                                contentEditable={true}
                                onBlur={(e) => handleFullTextChange(index, e)}
                            >
                                {block.fullText}
                            </span>
                        </Col>

                    </Row>
                </div>
            ))}
        </div>
    )
}

export default BlockList;
