const organizeData = (pages) => {
    const result = [];

    // 遍历每一页
    if (!pages) return result;
    pages.forEach(page => {
        // 遍历每一个区块
        page.blocks.forEach(block => {
            // 遍历每一个段落
            block.paragraphs.forEach(paragraph => {
                // 遍历每一个单词
                paragraph.words.forEach(word => {
                    // 遍历每一个字符
                    word.symbols.forEach(symbol => {
                        // 获取字符的左上角和右下角坐标
                        const start_x = symbol.boundingBox.vertices[0].x;
                        const start_y = symbol.boundingBox.vertices[0].y;
                        const end_x = symbol.boundingBox.vertices[2].x;
                        const end_y = symbol.boundingBox.vertices[2].y;

                        // 尝试获取该单词的检测语言
                        const detected_language = word.property?.detectedLanguages?.[0]?.languageCode || 'unknown';

                        result.push({
                            text: symbol.text,
                            start_x: start_x,
                            start_y: start_y,
                            end_x: end_x,
                            end_y: end_y,
                            detected_language: detected_language
                        });
                    });
                });
            });
        });
    });
    return result;
}

export default organizeData;
