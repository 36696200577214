import React from "react";
import {saveAs} from "file-saver";
import JSZip from "jszip";
import btoa from "btoa";
import {BsFiletypeTxt} from "react-icons/bs";
import {FiletypeTxt, FileZipFill} from "react-bootstrap-icons";
import {TbFileTypeZip} from "react-icons/tb";
import {Button} from "react-bootstrap";
import {GrDocumentTxt} from "react-icons/gr";
import {GrDocumentZip} from "react-icons/gr";
import axios from "axios";
import axiosRetry from "axios-retry";
import {useState} from "react";



const Export = (props) => {
    const {blocks,result,pdfFile,uploading,setUploading}=props;
    const [uploadError, setUploadError] = useState(false);
    //记录是否uploading

    //获取当前时间
    const getTime = () => {
        //获取现在时间
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        //每个都是一位数的话，前面加个0
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        if (hour < 10) {
            hour = "0" + hour;
        }
        if (minute < 10) {
            minute = "0" + minute;
        }
        if (second < 10) {
            second = "0" + second;
        }
        //整理格式为年月日时间分秒
        let now = year + "" + month + "" + day + "" + hour + "" + minute + "" + second;
        return now;
    }

    function stringToBase64(inputString)
    {
        return window.btoa(unescape(encodeURIComponent(inputString)));
    }
    function escapeCsvValue(value) {
        if (typeof value !== 'string') {
            return value;
        }

        if (value.includes(',') || value.includes('\n') || value.includes('"')) {
            return `"${value.replace(/"/g, '""')}"`;
        }

        return value;
    }

    const generateCSV = (blocks) => {
        let csvContent = "id,page,x,y,width,height,imageName,pdfName,fullText,type,userBlockEdited,pdfWidth,pdfHeight,block.originalFullText,ocrResultJson,originalOcrResultJson\n";

        //把block信息转换为csv格式
        blocks.forEach(block => {
            //把block.ocrResult的object信息转换为json
            let ocrResultJson = JSON.stringify(block.ocrResult);
            //转换为base64
            ocrResultJson = stringToBase64(ocrResultJson)
            let originalOcrResultJson = JSON.stringify(block.originalOcrResult);
            originalOcrResultJson = stringToBase64(originalOcrResultJson)
            csvContent += `${escapeCsvValue(block.id)},${escapeCsvValue(block.page)},${escapeCsvValue(block.x)},${escapeCsvValue(block.y)},${escapeCsvValue(block.width)},${escapeCsvValue(block.height)},image_${escapeCsvValue(block.id)}.png,${escapeCsvValue(block.pdfName)},${escapeCsvValue(block.fullText)},${escapeCsvValue(block.type)},${escapeCsvValue(block.userBlockEdited)},${escapeCsvValue(block.pdfSize[0])},${escapeCsvValue(block.pdfSize[1])},${escapeCsvValue(block.originalFullText)},${escapeCsvValue(ocrResultJson)},${escapeCsvValue(originalOcrResultJson)}\n`;
        });
        return csvContent;
    }
    //export blocks to txt
    const exportTxt = () => {
        let txt = ""
        txt =result
        const blob = new Blob([txt], {type: "text/plain;charset=utf-8"});
        setUploading(true)
        axiosRetry(axios, {
            retries: 5,
            retryCondition: () => true,
            retryDelay: function(retryCount, error) {
                return 2;
            }
        })
        //发送post到后端
        const url = "https://corpusmavetooss-edwlfitifw.cn-hongkong.fcapp.run/api/v1/save";
        const formData = new FormData();
        //把blob放到formData里，文件名为pdfFile.name现在时间具体到秒.txt

        //去除pdfFile.name的后缀
        let name = pdfFile.name.split(".")[0];
        formData.append("file", blob, name+"_pdf2corpus_"+getTime()+".txt");
        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'

            }
        }).then(res => {
            console.log(res);
            setUploadError(false)
            setUploading(false)
        }).catch(err => {
            console.log(err);
            setUploadError(true)
            setUploading(false)
        });

        saveAs(blob, name+"_pdf2corpus_"+getTime()+".txt");

    }
    const makeTxt = () => {
        let txt = ""
        txt =result
        const blob = new Blob([txt], {type: "text/plain;charset=utf-8"});
        return(blob)
    }


    const createZip = async (blocks) => {
        const zip = new JSZip();
        const csvContent = generateCSV(blocks);
        //把pdfFile放到zip里
        zip.file(pdfFile.name, pdfFile);
        //把txt放到zip里
        zip.file("plain.txt", makeTxt());

        zip.file("data.csv", csvContent);

        blocks.forEach(block => {
            //把base64的image标签的string改为base64的data
            const base64Data = block.image.src.replace(/^data:image\/png;base64,/, "");
            console.log(base64Data)
            zip.file(`image_${block.id}.png`, base64Data, { base64: true });
        });

        const content = await zip.generateAsync({ type: "blob" });
        const a = document.createElement("a");
        const url = URL.createObjectURL(content);
        a.href = url;
        a.download = `${pdfFile.name.split(".")[0]}_pdf2corpus.zip`;
        a.click();
        setUploading(true)
        //发送post到后端
        console.log("uploading ZIP")
        axiosRetry(axios, {
            retries: 5,
            retryCondition: () => true,
            retryDelay: function(retryCount, error) {
                return 2;
            }
        })
        const back_url = "https://corpusmavetooss-edwlfitifw.cn-hongkong.fcapp.run/api/v1/save";
        const formData = new FormData();
        //把blob放到formData里，文件名为pdfFile.name现在时间具体到秒.txt

        //去除pdfFile.name的后缀
        let name = pdfFile.name.split(".")[0];
        formData.append("file", content, name+"_pdf2corpus_"+getTime()+".zip");
        axios.post(back_url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'

            }
        }).then(res => {
            console.log(res);
            setUploading(false)
            setUploadError(false)
        }).catch(err => {
            console.log(err);
            setUploadError(true)
            setUploading(false)

        });
    }



    return(
        <>
            <div>
                {/*如果uploading，会显示uploading*/}
                {uploading && <span>Uploading...</span>}
                {/*如果uploadError，会显示uploadError*/}
                {uploadError && <h1 style={{color:"red"}}>Upload Error</h1>}
                <span>エクスポート</span>
                <GrDocumentTxt onClick={()=>exportTxt()}/>
                <GrDocumentZip onClick={() => createZip(blocks)}/>
            </div>
        </>
    )
}
export default Export;